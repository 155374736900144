import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Faqs = () => (
  <Layout>
  <Seo title="FAQs" />

  <h1>Frequently Asked Questions</h1>

<p>
<i><b>How long do the sessions last?</b></i>
<br />
While the initial session may last a little longer, individual,
couples or family therapy sessions are usually 50 minutes.
Occasionally, 90-minute sessions are scheduled for EMDR treatment
or if a circumstances call for an extended time.
</p>

<p>
<i><b>How often will we meet?</b></i>
<br />
At the beginning of the therapeutic process, sessions are
usually scheduled once a week. This frequency allows for
consistent contact so that treatment can be closely monitored,
questions answered, and the therapeutic relationship is strengthened.
As desired changes become integrated, the frequency will be extended
to every two or three weeks, followed by "tune-up's" or "check-in's"
as needed.
</p>

<p>
<i><b>How many sessions will be needed?</b></i>
<br />
The answer to this question depends on what is being addressed in
therapy, the extent and impact of the changes, treatment plan, and
commitment to follow-through with therapeutic recommendations. Once
there is clarity about these variables, we can have a better idea of
the probable length of treatment. While some shifts occur within a
relatively brief period of time (after 3-6 sessions), others require
a more extensive, intensive focus. Because most insurance plans limit
the number of sessions covered by policy benefits, it is important to
be clear about your coverage at the beginning of therapy (see "Insurance
Coverage Checklist").
</p>

<p>
<i><b>What about medication?</b></i>
<br />
The focus of our work will be the integration of understanding and working
with patterns of thought, behavior and emotions, along with physical care,
that lead to desired changes and/or growth. Usually, medication is not an
essential part of this process. However, if there is significant distress
that is impairing personal, job, or educational functioning, the options of
medication would be discussed. If there is a decision to proceed with medication,
the next step would be an appointment with your primary care physician or a
psychiatrist for a medication assessment and, if indicated, prescription.
Ongoing monitoring by the physician would be essential in assuring optimal m
edication treatment.
</p>

<p>
<i><b>What about confidentiality?</b></i>
<br />
All client records are kept under security, and the telephone voicemail is completely
confidential. Information about you or your family will not be released without your
prior written consent, with the following exceptions: when there is suspicion of
child or elder neglect or abuse; when there is reasonable belief that you are a
danger to yourself or to others; or when there is a court subpoena for records
or information.
</p>

<p>
<i><b>What is your cancellation policy?</b></i>
<br />
For appointments cancelled at least 48 hours prior to the appointment time, there is no charge for the cancellation. For appointments cancelled with less than 48 hours notice or if you do not appear for your scheduled appointment, you will be held responsible for the full regular fee. Please note if you are using insurance you will be responsible for the full contract rate of the session, not just the co-pay.
<br />

Appointments may be cancelled by leaving a message at the office number (831-373-1017), 24 hours a day, seven days a week.
</p>



  </Layout>
)

export default Faqs
